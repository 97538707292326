import React, { useState } from 'react'
import Navbar from "../../Component/Nav"
import TopFooter from '../../Component/Footer/TopFooter'
import './landing.scss'
import landingimage from "../../Image/landingimage.svg"
export default function Index() {

  return (
    <div>
      {/* <TopFooter 
      setmarketveris={setmarketveris}
      marketveris={marketveris}
      /> */}
      <Navbar 
    
      />

      <div className='landing-page'>
        <div className='innerlanding'>
          <div className='left'>
            <h1>Powering The Next Gen Of <span>Health</span> Care Providers</h1>
            <p>Automate your practice and empower your team with our cloud-based suite.</p>
          </div>
          <img src={landingimage} />
        </div>
      </div>
      <div className='sectionTwo'>
        <div className='sectionTwoInner'>
          <h1>Explore Solutions For:</h1>
          <div className='tabs'>

            <div className='tabtitle'>
              Primary Care Clinics
            </div>
            <div className='tabtitle'>
              Specialized Clinics
            </div>
            <div className='tabtitle'>
              Home Care
            </div>
            <div className='tabtitle'>
              Pharmacies
            </div>

            <div className='tabtitle'>
              AYUSH
              <div className='new'>
                New
              </div>
            </div>
          </div></div>
      </div>
    </div>
  )
}
