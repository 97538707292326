import React from 'react'
import './TopFooter.scss'
import xccxcfrom from "../../Image/xccxc.svg"
export default function TopFooter({ setmarketveris, marketveris, toggle,settoggle }) {
    return (
        <div className='TopFooter'
            style={{
                background: toggle?.Platforms ? " #fff" : marketveris && " #fff"
            }}

        >
            <div className='inner'

                style={{
                    background: toggle?.Platforms ? " #fff" : marketveris && " #fff"
                }}
            >

                <div className='poweredby'>
                    Powered By:

                    <div className='dropdowmlist'

                        style={{
                            color: toggle?.Platforms || marketveris ? " #010101" : "#FFF"
                        }}
                        onClick={() => {
                            setmarketveris(!marketveris)
                            settoggle({
                                Platforms: false
                            })
                        }

                        }
                    >
                        <img src={xccxcfrom}
                            style={{
                                marginRight: "6px",
                                marginLeft: "6px",
                                height: "14px"
                            }}
                        />

                        Mediverse
                        {
                            toggle?.Platforms || marketveris ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                    <path d="M0.662279 0.943083L3.13667 4.74585C3.32701 5.08472 3.74577 5.08472 3.97417 4.74585L6.44856 0.943083C6.71505 0.52893 6.44856 0.00178814 6.02983 0.00178814L1.08101 0.00178814C0.662279 -0.0358572 0.395792 0.528903 0.662279 0.943083Z" fill="#C51E25" />
                                </svg>
                                :
                                <svg
                                    style={{
                                        transform: marketveris && "rotate(61deg)"
                                    }}

                                    xmlns="http://www.w3.org/2000/svg" width="7" height="5" viewBox="0 0 7 5" fill="none">
                                    <path d="M0.361254 0.943083L2.83565 4.74585C3.02598 5.08472 3.44474 5.08472 3.67314 4.74585L6.14754 0.943083C6.41402 0.52893 6.14754 0.00178814 5.7288 0.00178814L0.779987 0.00178814C0.361253 -0.0358572 0.0947671 0.528903 0.361254 0.943083Z" fill="white" />
                                </svg>

                        }

                    </div>
                </div>

                <div className='rightside' >

                    <div
                        className='title'
                        style={{
                            color: toggle?.Platforms || marketveris ? " #010101" : "#FFF"
                        }}

                    >Contact</div>
                    <div
                        className='title'
                        style={{
                            color: toggle?.Platforms || marketveris ? " #010101" : "#FFF"
                        }}
                    >Login</div>
                    <div
                        className='title'

                        style={{
                            color: toggle?.Platforms || marketveris ? " #010101" : "#FFF"
                        }}
                    >Support</div>
                </div>
            </div>
        </div >
    )
}
